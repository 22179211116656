@use '../abstracts/mixins' as *;
@use '../abstracts/type' as t;
@use '../abstracts/colors' as c;
@use '../helpers/variables' as v;

.block {
    display: block;
}

// .flex {
//     // display: flex !important;
//     display: flex;
// }

.flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.grid {
    display: grid;
}

.mat-drawer-container {
    height: 100vh;
    width: 100%;
}

.thrive-table_mobile {
    width: 100%;
    // border: 1px solid;
    border-radius: v.$border-radius;
    overflow: hidden;

    tr td {
        padding: 10px;
        vertical-align: middle;

        &:nth-child(odd) {
            font-weight: bold;
        }
    }
}

.thrive-container {
    max-width: 1140px;
    margin: 20px auto 0 auto;

    &.table-grid-container {
        display: grid;
        row-gap: 16px;

        // .toolbar {
        //     margin-bottom: 0 !important;
        // }
    }

    .toolbar {
        // @include get-grid(center, space-between, 0, 16px, 1fr);
        // margin-bottom: 16px;
        // width: 100%;

        @include respond-to('small') {
            max-width: calc(100vw - 30px);
            // @include get-grid(center, space-between, 16px, 0, 1fr);
        }

        .toolbar-sections {
            @include get-grid(center, space-between, 0, 16px);
            // 1fr 45px 45px 110px 110px
            width: 100%;

            &.only-create {
                // @include get-grid(center, space-between, 0, 16px, 1fr auto);
                grid-template-columns: 1fr auto;
            }

            // &.no-create {
            //     // @include get-grid(center, space-between, 0, 16px, 1fr 45px 110px !important);
            //     // grid-template-columns:1fr auto auto;

            //     // @include respond-to('small') {
            //     //     grid-template-columns: unset;
            //     //     grid-template-rows: 2fr;
            //     //     row-gap: 16px;
            //     //     .filter {
            //     //         grid-area: 2/3 / span 1 / span 2 !important;
            //     //     }
            //     // }
            // }

            @include respond-to('small') {
                max-width: calc(100vw - 30px);
                // @include get-grid(center, space-between, 16px, 0px, auto auto auto auto auto auto);

                // h2 {
                //     grid-area: 1 / 1 / span 1 / span 4;
                // }

                // .btn-thrive_primary {
                //     // grid-area: 1 / 5 / span 1 / span 2;
                //     // margin-left: auto;
                // }

                // .search-bar {
                //     // grid-area: 2 / 1 / span 1 / span 3;
                //     grid-area: 2 / 1 / 2 / 2;
                //     // width: 170px !important;
                // }

                // .btn-thrive_icon {
                //     grid-area: 2 / 4 / span 1 / span 1;
                // }

                // .filter {
                //     width: 110px;
                //     grid-area: 2 / 5 / span 1 / span 2;
                //     margin-left: auto;
                // }
            }

            h2 {
                @include get-flex(center, $columnGap: 20px);

                mat-icon {
                    font-family: 'Material Icons Outlined';
                    color: #580D7E;
                }
            }

            &:last-child {
                button.btn-thrive_primary {
                    min-width: 110px;
                }
            }

            .search-bar {
                position: relative;
                height: 45px;
                width: 45px;

                .desktop {
                    display: block;
                }

                .mobile {
                    display: none;
                }

                @include respond-to('small') {
                    .desktop {
                        display: none;
                    }

                    .mobile {
                        display: block;
                    }
                }

                button {
                    position: absolute;
                    top: 0;
                    right: 0;
                }

                input {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 48px;
                    padding: 20px;
                    border-radius: v.$border-radius;
                    border: 1px solid map-get(c.$colors, 'primary', '500');

                    @include respond-to('small') {
                        padding: 0px;
                    }
                }
            }
        }
    }

    .edit-toolbar {
        @include get-grid(center, space-between, 0, 16px, 1fr);
        margin-bottom: 16px;
        width: 100%;

        .edit-toolbar_heading {
            span {
                display: flex;
                align-items: center;
                font-size: 14px;
                cursor: pointer;
                column-gap: 5px;
                font-weight: bolder;
                font-family: 'Thrive bold';

                &:hover {
                    color: #580D7E;
                }
            }

            @include get-grid(center, space-between, 0, 16px, auto 1fr auto);

            @include respond-to('small') {
                grid-template-columns: 75px 1fr;
                column-gap: 16px;
            }
        }
    }

    // .thrive-card.form-card {
    //     height: calc(100vh - 240px);
    //     overflow: overlay;
    // }

    .action-footer {
        margin-top: 20px;
        @include get-flex(center, center);
        position: fixed;
        bottom: 0;
        padding: 8px 20px;
        width: calc(100% - 82px);
        right: 0;
        background: #fff;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px -0.5px 2px rgba(96, 97, 112, 0.16);

        .footer-container {
            max-width: 1140px;
            width: 100%;
            @include get-flex(center, flex-end);
        }

        @include respond-to('small') {
            left: 0px;
            padding: 10px;
            width: 100%;
        }
    }
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

// Margin Top
.mt-0 {
    margin-top: 0px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-16 {
    margin-top: 16px;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-24 {
    margin-top: 24px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-70 {
    margin-top: 70px;
}

// Margin Bottom
.mb-0 {
    margin-bottom: 0px !important;
}

.mb-4 {
    margin-bottom: 4px;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-24 {
    margin-top: 24px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-60 {
    margin-bottom: 60px;
}

// Margin Left
.ml-auto {
    margin-left: auto;
}

.ml-0 {
    margin-left: 0px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-30 {
    margin-left: 30px;
}

// Margin Right
.mr-auto {
    margin-right: auto;
}

.mr-0 {
    margin-right: 0px !important;
}

.mr-10 {
    margin-right: 10px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-30 {
    margin-right: 30px;
}

// Common Margins
.mx-auto {
    margin: 0 auto;
}

.mx-16 {
    margin: 0 16px;
}

.my-auto {
    margin: auto 0;
}

.my-24 {
    margin: 24px 0;
}

.my-20 {
    margin: 20px 0;
}

.my-16 {
    margin: 16px 0 !important;
}

.my-10 {
    margin: 10px 0;
}

.my-8 {
    margin: 8px 0;
}

.m-16 {
    margin: 16px;
}

// Padding Top
.pt-0 {
    padding-top: 0px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-16 {
    padding-top: 16px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-30 {
    padding-top: 30px;
}

// Padding Bottom
.pb-0 {
    padding-bottom: 0px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-30 {
    padding-bottom: 30px;
}

// Padding Left
.pl-auto {
    padding-left: auto;
}

.pl-0 {
    padding-left: 0px;
}

.pl-10 {
    padding-left: 10px;
}

.pl-16 {
    padding-left: 16px;
}

.pl-20 {
    padding-left: 20px;
}

.pl-30 {
    padding-left: 30px;
}

// Padding Right
.pr-auto {
    padding-right: auto;
}

.pr-0 {
    padding-right: 0px;
}

.pr-10 {
    padding-right: 10px;
}

.pr-16 {
    padding-right: 16px;
}

.pr-20 {
    padding-right: 20px;
}

.pr-30 {
    padding-right: 30px;
}

// Common Paddings
.px-auto {
    padding: 0 auto;
}

.py-auto {
    padding: auto 0;
}

.py-20 {
    padding: 20px 0;
}

.py-24 {
    padding: 24px 0;
}

.px-24 {
    padding: 0 24px;
}

.p-0 {
    padding: 0px !important;
}

.p-24 {
    padding: 24px !important;
}

.bold {
    font-family: 'Thrive Bold';
    font-weight: 700;
}

.thrive-img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
}

.breadcrumbs {
    font-size: map-get(t.$font, 'size', '200');
}

.disabled {
    opacity: 0.4;
    pointer-events: none;
}

.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.verified-success {
    color: map-get(c.$colors , 'success', '200');
    height: 45px;
    @include get-flex(center, center, $columnGap: 15px);

    @include respond-to('small') {
        height: 30px;
        @include get-flex($justifyContent: flex-start);
    }
}
 /* Hide the arrow buttons in Chrome, Safari, Edge, and Opera */
 input[type="number"]::-webkit-outer-spin-button,
 input[type="number"]::-webkit-inner-spin-button {
 -webkit-appearance: none;
 margin: 0;
 }

 /* Hide the arrow buttons in Firefox */
 input[type="number"] {
 -moz-appearance: textfield;
 }