/* You can add global styles to this file, and also import other style files */
@use '../../../sass/abstracts';
@use '../../../sass/abstracts/colors' as c;
@use '../../../sass/base';
@use '../../../sass/helpers';
@use '../../../sass/modules';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '../node_modules/@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';
@import '../node_modules/sweetalert2/dist/sweetalert2.min.css';

* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    outline: none;
    // font-family: 'Thrive Regular';
}

/* body {font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;} */

.basic-container {
    background-color: #fff;
    padding: 105px 20px 80px;
    min-height: 100vh;
}

.mat-mdc-menu-content {
    padding: 0px !important;
}

// sweetalert styling
.swal2-actions {
    flex-direction: row-reverse !important;
}

.swal2-styled.swal2-confirm {
    background-color: #FFF32A !important;
    color: #000 !important;
    border: 1px solid #000 !important;
    border-radius: 4px !important;
    box-shadow: 2px 2px 0px 0px #000000;
    padding: 10px 20px !important;
    max-height: 45px;
    height: 45px !important;
    text-transform: uppercase !important;
    font-size: 0.889rem !important;
    font-weight: 700 !important;
    cursor: pointer;
}

.swal2-styled.swal2-default-outline:focus {
    box-shadow: 2px 2px 0px 0px #000000 !important;
    outline: 0;
}

.swal2-styled.swal2-cancel {
    padding: 10px 20px !important;
    max-height: 45px;
    height: 45px !important;
    cursor: pointer;
    background: transparent;
    border-radius: 4px !important;
    border: 1px solid #580D7E !important;
    font-size: 0.889rem !important;
    font-weight: 700 !important;
    background: transparent !important;
    box-shadow: none;
    position: relative;
    color: #580D7E !important;
    --mdc-text-button-label-text-color: #580D7E !important;
    text-transform: uppercase !important;

    &:hover {
        box-shadow: 2px 2px 0px 0px #580D7E;
    }
}

.inner-container {
    /* overflow-y: auto;
  max-height: calc(100vh - 130px);
  min-height: calc(100vh - 130px); */
    /* padding-right: 5em; */
    /* padding-right: 1em; */
    /* padding-left: 1em; */
    padding-bottom: 2em;
}

.inner-container::-webkit-scrollbar-track {
    border: 1.5px solid #f47920;
    border-radius: 10px;
    background-color: #fff;
}

/* tooltip notch for bottom tooltip */
.thrive-tooltip[style*='transform-origin: center top']::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #3a3a3a transparent;
}

/* tooltip notch for left tooltip */
.thrive-tooltip[style*='transform-origin: left center']::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #3a3a3a transparent transparent;
}

/* tooltip notch for right tooltip */
.thrive-tooltip[style*='transform-origin: right center']::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #3a3a3a;
}

/* tooltip notch for top tooltip */
.thrive-tooltip[style*='transform-origin: center bottom']::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #3a3a3a transparent transparent transparent;
}

.thrive-tooltip .mdc-tooltip__surface {
    font-size: 12px;
    line-height: 18px;
    font-family: 'Thrive Regular';
    background: #3a3a3a;
    color: #fff;
    border-radius: 4px;
    padding: 8px;
    text-align: center !important;
}

.inner-container::-webkit-scrollbar {
    width: 8px;
    background-color: #fff;
}

:ng-deep .mat-bottom-sheet-container {
    padding: 0 !important;
}

.inner-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #f47920;
}

.thrive-bottom-dialog .dialog-header {
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #f8f8f8;
}

.thrive-bottom-dialog .dialog-content {
    padding: 16px;
    max-height: calc(100vh - 200px);
    overflow-y: overlay;
}

.thrive-bottom-dialog .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px;
}

:ng-deep .mat-calendar-body-label {
    padding-top: 0 !important;
}

/* basic styles */

// .stepper__tour {
//     color: #580D7E;
//     display: flex;
//     align-items: center;
//     column-gap: 7px;
//     cursor: pointer;
//     border: 1px solid #580D7E;
//     padding: 10px;
//     border-radius: 10px;
//     font-size: 16px;
// }

// .stepper__tour mat-icon {
//     font-size: 18px;
//     height: 18px;
//     width: 18px;
// }

.no-scroll {
    overflow: hidden !important;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.color-success {
    color: #67c900;
}

.fw500 {
    font-weight: 500;
}

.fsi {
    font-style: italic;
}

.v_middle {
    vertical-align: middle;
}

.p-l-r-24 {
    padding: 0px 24px;
}

.ml-auto {
    margin-left: auto;
}

.mr-auto {
    margin-right: auto;
}

.mt15 {
    margin-top: 15px;
}

.m-t-0 {
    margin-top: 0px !important;
}

.m-b-0 {
    margin-bottom: 0px !important;
}

.m-t-5 {
    margin-top: 5px !important;
}

.m-b-5 {
    margin-bottom: 5px !important;
}

.m-t-30 {
    margin-top: 30px !important;
}

.m-b-30 {
    margin-bottom: 30px;
}

.m-r-20 {
    margin-right: 20px;
}

.m-r-30 {
    margin-right: 30px;
}

.ml15 {
    margin-left: 15px;
}

.mt60 {
    margin-top: 60px;
}

.mx-10 {
    margin-left: 10px;
    margin-right: 10px;
}

// .mx-20 {
//     margin-left: 20px;
//     margin-right: 20px;
// }

.my-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.m-l-auto {
    margin-left: auto;
}

.m_r_85 {
    margin-right: 85px;
}

.mr20 {
    margin-right: 20px;
}

.w-100 {
    width: 100% !important;
}

.w-80 {
    width: 80%;
}

.w-70 {
    width: 70%;
}

.w-60 {
    width: 60%;
}

.w-50 {
    width: 50% !important;
}

.w-30 {
    width: 30%;
}

.w-20 {
    width: 20%;
}

.w-15 {
    width: 15%;
}

.w-10 {
    width: 10%;
}

.h-100 {
    height: 100%;
}

/* columns */

.col-4 {
    flex: 0 1 calc(33.33% - 48px);
}

.col-6 {
    flex: 0 1 calc(50% - 48px);
}

.cb {
    clear: both;
}

.pointer {
    cursor: pointer;
}

.no-event {
    pointer-events: none !important;
}

.hide {
    display: none;
}

// .flex {
//     display: -webkit-box;
//     display: -moz-box;
//     display: -ms-flexbox;
//     display: -webkit-flex;
//     display: flex;
// }

// .grid{
//     display: grid;
// }

.align-center {
    align-items: center;
}

.justify-space-between {
    justify-content: space-between;
}

.inline {
    display: inline-block;
}

.clickable {
    cursor: pointer;
}

.relative {
    position: relative;
}

.disabled-button {
    background-color: #dadada !important;
    pointer-events: none;
}

.disabled-block {
    opacity: 0.4;
    pointer-events: none;
}

.version-info {
    font-size: 8pt;
    float: right;
}

.common-search-box {
    border: solid 1px #979797;
    border-radius: 25px;
    padding: 5px 0px 5px 10px;
    align-items: center;
}

.common-search-box input {
    border: none;
    caret-color: #3d3d3d;
    width: calc(100% - 30px);
}

.common-search-box .mat-icon {
    font-size: 18px;
    height: auto;
}

.action-button {
    color: #fff;
    background-color: #f47920;
    font-size: 11px;
    /* padding: 10px 20px; */
    padding: 8px 16px;
    border-radius: 8px;
    font-family: 'headlinesFont';
    font-weight: normal;
    border: none;
    cursor: pointer;
    outline: none;
}

.submit-button {
    color: #fff;
    background-color: #f47920;
    font-size: 11px;
    padding: 10px 20px;
    border-radius: 24px;
    font-family: 'headlinesFont';
    font-weight: normal;
    border: none;
    cursor: pointer;
    outline: none;
    text-transform: uppercase;
}

.btn {
    border-radius: 8px;
    font-size: 13px;
    padding: 10px 20px;
    cursor: pointer;
    text-transform: none;
    font-family: 'Thrive Regular';
}

.btn--lg {
    padding: 13px;
    min-width: 170px;
    font-size: 16px;
}

.btn--thrive--light {
    background: #580D7E1a !important;
    color: #580D7E !important;
    border: 1px solid #580D7E !important;
}

.btn--thrive:disabled {
    background: #c9c9c9;
    color: #3a3a3a;
    cursor: not-allowed;
    border-color: transparent;
}

.btn--white {
    background: #fff;
    border: 1px solid #888888;
    color: #888888;
    min-width: 150px;
}

.bth--thrive--border {
    background: #ffffff;
    border: 1px solid #580D7E;
    color: #580D7E;
    min-width: 150px;
}

.bth--thrive--border:disabled {
    border: 1px solid #3a3a3a;
    color: #3a3a3a;
    cursor: not-allowed;
}

.btn--thrive {
    background-color: #580D7E;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border: 1px solid #580D7E;
}

.btn--thrive .btn-loader {
    display: none;
}

.btn__thrive--loader {
    display: flex;
    align-items: center;
    /* pointer-events: none; */
}

.btn__thrive--loader:has(.btn-loader) {
    pointer-events: none;
}

.btn__thrive--loader .btn-loader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #580D7E;
    margin-right: 15px;
    width: 15px;
    height: 15px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
    display: block;
    position: relative;
}

.submit-button-new {
    height: 35px;
    /* font-size: 0.7161458333333334vw; */
    font-size: 11px;
    border-radius: 10px;
    margin-top: 40px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'headlinesFont';
    color: #ffffff;
    padding: 7px 14px;
    border: none;
    width: 100%;
    background-color: #f47920;
}

.submit-button-new.disabled {
    opacity: 0.4;
    pointer-events: none;
}

.new-action-button {
    color: #2dcc70;
    border-radius: 15px;
    border: 1.5px solid #2dcc70;
    padding: 7px 14px;
    text-decoration: none;
    font-size: 13px;
    font-family: 'headlinesFont';
    font-weight: 600;
    cursor: pointer;
    display: flex;
    background-color: #ffffff;
}

.secondary-action-button,
.sub-secondary-action-button {
    font-size: 12px;
    min-width: 150px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'headlinesFont';
    padding: 9px 18px;
}

.secondary-action-button {
    background-color: #0093ff;
    color: #ffffff;
}

.secondary-action-button-type-2 {
    font-family: 'headlinesFont';
    border-radius: 15px;
    border: solid 1px #979797;
    padding: 2px 10px;
    letter-spacing: 0.04px;
    color: #5f5f5f;
    cursor: pointer;
}

.mat-mdc-form-field .mdc-text-field--filled {
    background-color: transparent !important;
}

.sub-secondary-action-button {
    color: #0093ff;
    background-color: #ffffff;
    border: 1.5px solid #0093ff;
}

.pro-feature-button {
    min-width: unset;
    font-size: 10px;
    padding: 6px 17px;
    border-radius: 7px;
}

.default-case-mesg {
    margin: 25px auto;
    font-size: 14px;
    font-weight: normal;
    height: 50vh;
    display: table;
}

.default-case-mesg>div {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.default-case-mesg>div>div:first-child {
    margin: 15px 0px;
}

.default-case-mesg>div>div mat-icon {
    font-size: 50px;
    width: 50px;
    height: 50px;
}

.box-shadow-card {
    box-shadow: 0 0 30px rgba(40, 40, 40, 0.1);
}

.error-msg {
    font-size: 12px;
    color: #cc372d;
}

.box-shadow-card:hover {
    /* transform: translateY(-3px); */
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.card-div {
    margin-top: 40px;
}

.mat-mdc-card-title {
    color: #5e5e5e;
    font-weight: normal;
    font-family: 'Lato';
}

/* tags */

/* .tag_button{color: #e78239;border-radius: 24px;border: solid 2px #e78239;background-color: #fff;box-shadow: none;font-size: 12px;line-height: 18px;min-width: 100px;font-weight: 500;font-family: 'Lato';padding: 4px 10px;margin: 5px;}
.tag_selected_button{color: #fff;background-color: #e78239;}
.tag_button .mat-button-wrapper{display: flex;justify-content: space-between;}
.tag_button span.remove{background: #e78239;color:#fff;border-radius: 55%;position: relative;height: 16px;padding: 0px 8px;}
.tag_button span.remove .mat-icon {width: auto;height: auto;position: absolute;font-size: 10px;top: 3.5px;left: 3px;}
.tag_button.tag_selected_button span.remove{background: #fff;color:#e78239} */

/* table styles */
.mdc-data-table__cell,
.mdc-data-table__header-cell {
    border-bottom-width: 0px !important;
    border-bottom-style: none !important;
}

.dashboard_table {
    box-shadow: 0 0 45px rgba(40, 40, 40, 0.1);
}

.dashboard_table:hover {
    /* transform: translateY(-3px); */
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.dashboard_table .mat-mdc-card-header {
    margin-bottom: 25px;
    align-items: center;
    justify-content: space-between;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.dashboard_table .mat-card-header-text {
    width: 70%;
}

.dashboard_table .table_title {
    color: #5e5e5e;
    font-family: 'Lato', sans-serif;
    text-align: left;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
}

.dashboard_table .search_div {
    width: 25%;
    border: 0.5px solid #3d3d3d;
    border-radius: 25px;
    padding: 1.5px 0px;
    align-items: center;
    padding: 5px;
    height: 30px;
}

/* .dashboard_table .search_div .mat-form-field-underline {
  display: none;
} */

/* .dashboard_table .search_div .mat-form-field {
  width: 95%;
} */

/* .dashboard_table .search_div .mat-form-field-infix {
  padding: 0;
  border: none;
} */

/* .dashboard_table .search_div input.mat-input-element {
  margin-top: 0;
  font-family: "Lato";
  font-size: 13px;
} */

/* .dashboard_table .search_div .mat-form-field-flex {
  align-items: center !important;
} */

/* .dashboard_table .search_div .mat-form-field-suffix,
.dashboard_table .search_div .mat-form-field-infix {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
} */

.dashboard_table .search_div .mat-icon {
    font-size: 16px;
    width: 16px;
    height: auto;
}

/* .dashboard_table .search_div .mat-input-element {
  caret-color: #3d3d3d !important;
} */

.dashboard_table .search_div input {
    caret-color: #3d3d3d !important;
    font-family: 'Lato';
    font-size: 13px;
    width: calc(100% - 16px);
    border: none;
    padding: 0px 5px;
}

/* .dashboard_table .search_div .mat-form-field-wrapper {
  padding-bottom: 0;
}

.dashboard_table .search_div .mat-input-underline {
  display: none;
}

.dashboard_table .search_div .mat-form-field {
  text-align: center;
}

.dashboard_table .select_div .mat-form-field-infix {
  padding: 0;
  border: none;
} */

.dashboard_table .mat-mdc-table {
    display: initial;
}

.dashboard_table .mat-mdc-row {
    border-image-source: linear-gradient(to right, #ffffff, #cacaca);
    border-image-slice: 1;
    border-top-width: 1px;
    border-top-style: solid;
    border-bottom: none;
    min-height: 0px;
    padding: 10px 24px;
}

.dashboard_table .mat-mdc-header-row {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-image-source: linear-gradient(to right, #ffffff, #232323);
    border-image-slice: 1;
    border-top: 1px;
    border-top-style: solid;
    padding: 7px 24px;
    min-height: 0px;
}

.dashboard_table .mat-mdc-header-cell,
.dashboard_table .mat-header-cell .mat-sort-header-container .mat-sort-header-button {
    letter-spacing: 0.9px;
    /* font-style: italic; */
    color: #5e5e5e;
    font-weight: bold;
    font-size: 12px;
    /* text-transform: capitalize; */
    font-family: 'headlinesFont';
    /*display: block;*/
}

.dashboard_table .table_data .mat-mdc-cell {
    font-weight: normal;
    letter-spacing: 0px;
    /* font-style: italic; */
    font-family: 'contentFont';
    color: #989898;
    font-size: 11px;
    /*display: block;*/
}

.dashboard_table .table_data .mat-mdc-cell .fsi {
    font-size: 12px;
    text-transform: capitalize;
    letter-spacing: 0.9px;
}

.dashboard_table .footer {
    text-align: center;
    padding-bottom: 20px;
}

/* table pagination css */

.table-pagination .ngx-pagination {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-pagination .ngx-pagination a {
    font-size: 11px;
    color: #868686;
    font-family: 'Lato';
    font-weight: 300;
}

.mat-expansion-panel.mat-expanded {
    border: 1px solid #c9c9c9;

    .mat-expansion-panel-header {
        border-top: 1px solid transparent;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        height: 55px;
    }
}

.mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16) !important;
}

.mat-expansion-panel .mat-expansion-panel-header {
    border: 1px solid #c9c9c9;
    height: 55px;
}

.mat-expansion-panel .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
    background: transparent !important;
    border: 1px solid #580D7E;
}

.table-pagination .ngx-pagination .current {
    font-size: 12px;
    background: #4b19ff !important;
    border-radius: 4px;
}

.table-pagination .ngx-pagination .pagination-previous a::before,
.table-pagination .ngx-pagination .pagination-previous.disabled::before,
.table-pagination .ngx-pagination .pagination-next a::after,
.table-pagination .ngx-pagination .pagination-next.disabled::after {
    content: 'keyboard_arrow_left' !important;
    font-family: 'Material Icons';
}

.table-pagination .ngx-pagination .pagination-next a::after,
.table-pagination .ngx-pagination .pagination-previous a::before {
    color: #363636;
}

.table-pagination .ngx-pagination .pagination-previous a::before,
.table-pagination .ngx-pagination .pagination-previous.disabled::before {
    padding-left: 0px;
    padding-top: 5px;
    font-size: 20px;
}

.table-pagination .ngx-pagination .pagination-next a::after,
.table-pagination .ngx-pagination .pagination-next.disabled::after {
    transform: rotate(180deg);
    padding-right: 0px;
    padding-bottom: 2px;
    font-size: 20px;
}

/* snackbar css */

.error-snackbar {
    background-color: #bd362f;
}

.tolltip-snackbar {
    background-color: #c2c2c2;
    color: #000000;
}

.snackbar__notification {
    background: #000000;
    color: #ffffff;
    font-size: 15px;
}

.mat-mdc-simple-snack-bar {
    justify-content: center !important;
}

/* mat select icon */

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-arrow {
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    border-left: 0px !important;
    border-right: 1.5px solid #232323 !important;
    border-top: 0px !important;
    border-bottom: 1.5px solid #232323;
}

.info-icon.mat-icon {
    /* color: #0093ff; */
    color: #256efb;
    font-size: 18px;
    height: 18px;
    width: 18px;
    margin-right: 4px;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-mdc-select:focus:not(.mat-select-disabled).mat-primary .mat-select-trigger {
    color: #63961c;
}

.mat-mdc-card {
    // padding: 24px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.mat-card-header-text {
    margin: 0 8px !important;
}

.mat-mdc-card-header .mat-mdc-card-title {
    font-size: 16px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-focused .mat-form-field-underline .mat-form-field-ripple,
.mat-form-field-underline .mat-form-field-ripple {
    height: 0px;
    visibility: hidden;
}

.bold {
    font-weight: bold;
}

/* backgrounds */
.bg-4e4949 {
    background: #4e4949;
}

/* common components css */
/* radio button */

::ng-deep .mdc-radio__outer-circle {
    --mdc-radio-selected-icon-color: #580D7E !important;
    border-color: var(--mdc-radio-selected-icon-color, #580D7E) !important;
}

::ng-deep .mdc-radio__inner-circle {
    --mdc-radio-selected-icon-color: #580D7E !important;
    border-color: var(--mdc-radio-selected-icon-color, #580D7E) !important;
}

.custom-radio-button {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-radio-button input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.radiomark {
    position: absolute;
    top: 4px;
    left: 0;
    border-radius: 55%;
    height: 13px;
    width: 13px;
    background-color: #d2d2d2;
}

.custom-radio-button:hover input~.radiomark {
    background-color: #0093ff;
    border: 2px solid #b2deff;
}

.custom-radio-button input:checked~.radiomark {
    background-color: #0093ff;
    border: 2px solid #b2deff;
}

/* custom check box */
.custom-checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: calc(50% - 7px);
    /* right: 0; */
    border-radius: 4px;
    height: 14px;
    width: 15px;
    background-color: #d2d2d2;
}

/* When the checkbox is checked, add a blue background */

.custom-checkbox input:checked~.checkmark {
    background-color: #0093ff;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */

.custom-checkbox input:checked~.checkmark:after {
    display: block;
}

.custom-checkbox .checkmark:after {
    left: 5px;
    top: 2px;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 1.5px 1.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* custom input */

.floating-label {
    position: absolute;
    top: -10px;
    font-size: 10px;
    padding: 0px 8px;
    background: #ffffff;
    pointer-events: none;
    color: #000000;
}

.floating-label.optional {
    right: 10px;
}

.card-div-new {
    margin-top: 60px;
    box-shadow: 0 0 45px rgba(40, 40, 40, 0.1);
    padding: 24px;
}

.card-heading,
.card-sub-heading {
    font-family: 'headlinesFont';
    padding: 0px 24px;
    margin: 15px 0px;
    cursor: default;
}

.card-heading {
    font-size: 18px;
    letter-spacing: 0.08px;
    color: #5e5e5e;
}

/* sub heading */

.card-sub-heading {
    font-size: 16px;
    letter-spacing: 0.06px;
    color: #5e5e5e;
}

.legend-text {
    color: #b0b0b0;
    font-size: 12px;
    padding: 0px 24px;
    margin: 15px 0px;
    font-family: 'headlinesFont';
}

/* metric number */

.metric-number {
    font-family: 'headlinesFont';
    padding: 0px 24px;
    margin: 15px 0px;
    /* font-size: 1.3020833333333333vw; */
    font-size: 18px;
    letter-spacing: -0.42px;
    color: #3a3a3a;
    cursor: default;
}

.small-matric-number {
    font-family: 'headlinesFont';
    margin: 15px 0px;
    font-size: 16px;
    color: #3a3a3a;
}

.metric-text,
.metric-text-small,
.metric-text-medium {
    font-family: 'contentFont';
    color: #5e5e5e;
}

.metric-text-small {
    font-size: 12px;
}

.metric-text-medium {
    font-size: 14px;
}

.redirection-url-link {
    color: #4a90e2;
    cursor: pointer;
    text-decoration: none;
    font-family: 'headlinesFont';
}

/* tooltip css */

.tooltip {
    position: relative;
}

.tooltip .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.tooltip .tooltip-text {
    min-width: 210px;
    max-width: 210px;
    padding: 10px 14px;
    color: #fff;
    background-color: #000;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    font-family: 'Thrive Regular';
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    border: 1px solid #000;
    display: none;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
}

.tooltip-text-info,
.tooltip .tooltip-text-info i::after {
    background: #edf4fc !important;
    color: #2e5aac !important;
    border: none !important;
    min-width: 300px !important;
}

.tooltip-text-danger,
.tooltip .tooltip-text-danger i::after {
    background: #feefef !important;
    color: #da1414 !important;
    border: none !important;
}

.tooltip-text-warning,
.tooltip .tooltip-text-warning i::after {
    background: #fef2e9 !important;
    color: #b95000 !important;
    border: none !important;
}

.icon-info {
    color: #939393;
    font-size: 20px;
}

.tooltip:hover .tooltip-text {
    display: block;
}

.tooltip .tooltip-text i {
    position: absolute;
    overflow: hidden;
}

.tooltip .tooltip-text i::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #000;
    border: 1px solid #000;
}

/* left tooltip */

.left {
    top: 50%;
    right: 100%;
    margin-right: 20px;
    transform: translate(0, -50%);
}

.tooltip .tooltip-text.left i {
    top: 50%;
    left: 100%;
    margin-top: -12px;
    width: 12px;
    height: 24px;
}

.tooltip .tooltip-text.left i::after {
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
}

/* bottom tooltip */

.bottom {
    top: 30px;
    left: 50%;
    transform: translate(-50%, 0);
}

.tooltip .tooltip-text.bottom i {
    bottom: 100%;
    left: 50%;
    margin-left: -12px;
    width: 24px;
    height: 12px;
}

.tooltip .tooltip-text.bottom i::after {
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
}

/* right tooltip */

.right {
    top: 50%;
    left: 100%;
    margin-left: 20px;
    transform: translate(0, -50%);
}

.tooltip .tooltip-text.right i {
    top: 50%;
    right: 100%;
    margin-top: -12px;
    width: 12px;
    height: 24px;
}

.tooltip .tooltip-text.right i::after {
    left: 0;
    top: 50%;
    transform: translate(50%, -50%) rotate(-45deg);
}

/* top tooltip */

.top {
    bottom: 40px;
    left: 50%;
    transform: translate(-50%, 0);
}

.tooltip .tooltip-text.top i {
    top: 100%;
    left: 50%;
    margin-left: -12px;
    width: 24px;
    height: 12px;
}

.tooltip .tooltip-text.top i::after {
    left: 50%;
    transform: translate(-50%, -50%) rotate(135deg);
}

/* date drop down */

/* .date-dropdown::ng-deep.mat-select-arrow{padding: 0px;border-bottom: 0px solid black !important;border-right: 0px solid black !important;margin:0 1px;}
.date-dropdown::ng-deep.mat-form-field-infix{width: 86px;padding: 8px 2px;} */

/* components */

select-box,
multi-select-box {
    display: inline-block;
    width: 100%;
}

/* input css */

.custom-input-head {
    margin-bottom: 0px;
    margin-top: 30px;
}

.custom-input,
.custom-date-time-input,
.custom-input-type-2 {
    position: relative;
    margin: 0px 24px 24px 0;
    width: calc(100% - 24px);
    border-radius: 4px;
    height: 30px;
    padding: 2px 10px;
    font-family: 'headlinesFont';
    border: solid 1.25px #000000;
}

.custom-input,
.custom-date-time-input {
    display: inline-block;
}

.custom-input-type-2 {
    align-items: center;
    justify-content: space-between;
}

.error {
    border: 2px solid #cc372d !important;
}

.custom-input.error .floating-label,
.custom-date-time-input.error .floating-label,
.custom-input-type-2.error .floating-label {
    color: #cc372d;
}

.custom-date-time-input label {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    height: 100%;
}

.custom-date-time-input input::placeholder,
.custom-date-time-input label,
.custom-input-type-2 label {
    color: #b0b0b0;
    font-size: 12px;
    white-space: nowrap;
}

.custom-input input {
    height: 100%;
    font-size: 12px;
    border: none;
    width: 100%;
    font-family: 'headlinesFont';
}

.custom-date-time-input input {
    top: 0;
    left: 10px;
    background: transparent;
    position: absolute;
    width: calc(100% - 20px);
    height: 100%;
    font-size: 12px;
    border: none;
    font-family: 'headlinesFont';
    text-align: right;
}

.custom-input.error input,
.custom-date-time-input.error input {
    color: #cc372d;
}

.custom-input input::placeholder,
.custom-date-time-input::placeholder,
textarea::placeholder {
    color: #b7b7b7;
}

/* custom radio box */

.custom-radio-box {
    margin: 15px 24px;
}

.custom-radio-box label {
    padding: 10px 20px;
    border: solid 2px #d8d8d8;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: #ffffff;
    cursor: pointer;
    color: #5e5e5e;
    font-size: 12px;
    line-height: 1.6;
    letter-spacing: -0.14px;
    font-family: 'headlinesFont';
}

.custom-radio-box input[type='radio']:checked+label {
    border: solid 2px #0093ff;
    background-color: rgba(0, 147, 255, 0.04);
    color: #0093ff;
}

.custom-input-type-2 .option-selection {
    cursor: pointer;
    width: fit-content;
    font-size: 12px;
    padding: 2.5px 0px;
    height: 20px;
    color: #b2b2b2;
    text-align: center;
    display: inline-block;
    margin: 0px 5px;
}

.custom-input-type-2 .option-selection:hover,
.custom-input-type-2 .option-selection.selected {
    border-bottom: 2px solid #0093ff;
    color: #0093ff;
    font-weight: 600;
}

/* select cards */

.campaign-card-container {
    flex-wrap: wrap;
}

.campaign-card {
    border-radius: 14px;
    border: solid 2px #d8d8d8;
    background-color: #ffffff;
    cursor: pointer;
    padding: 15px 20px;
    margin: 15px;
    position: relative;
    min-height: 150px;
    flex: 0 1 calc(33.33% - 30px);
}

.campaign-card:hover,
.campaign-card.selected {
    border: solid 2px #0093ff;
}

.campaign-card .campaign-card-icon {
    position: absolute;
    right: 15px;
    top: 15px;
}

.campaign-card>label {
    font-size: 1.171875vw;
    font-family: 'headlinesFont';
    margin: 10px 0px 20px 0px;
    display: inline-block;
    letter-spacing: 0.63px;
    color: #545454;
}

.campaign-card>p {
    font-size: 0.8463541666666666vw;
    line-height: 1.3020833333333333vw;
    width: 80%;
    margin: 0px 0px 15px 0px;
    font-family: 'contentFont';
    letter-spacing: 0.33px;
    color: #545454;
}

/* switch */

.switch {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 14px;
    margin: 0px 0px 0px 20px;
    vertical-align: middle;
}

.disableswitch {
    pointer-events: none;
    opacity: 0.7;
}

.switch input {
    opacity: 0;
    width: 28px;
    height: 0;
}

/* green switcher */

.switch .slideron {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: rgba(0, 147, 255, 0.3); */
    background-color: rgba(151, 151, 151, 0.37);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.switch .slideron:before {
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    left: 2px;
    bottom: 2px;
    /* background-color: #0093ff; */
    background-color: #979797;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.switch input:focus+.slideron {
    box-shadow: 0 0 1px rgba(103, 201, 0, 0.2);
    /* box-shadow: 0 0 1px rgba(0, 147, 255, 0.3); */
}

.switch input:checked+.slideron {
    /* background-color: rgba(0, 147, 255, 0.3); */
    background-color: rgba(98, 54, 255, 0.2);
    /* background-color: #0093ff; */
}

.switch input:checked+.slideron:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
    background-color: #580D7E;
    /* background-color: #0093ff; */
}

/* Rounded sliders */
.switch .slideron.round {
    border-radius: 34px;
}

.switch .slideron.round:before {
    border-radius: 50%;
}

/* credits metric container */
.credits-metrics-container {
    align-items: center;
}

.credits-metrics-container>div {
    width: 25%;
}

.credits-metrics-container>div:last-child {
    text-align: center;
}

hr.bottom-line {
    width: 33px;
    margin: 4px 0;
    margin-left: 24px;
    border: 0;
    border-bottom: solid 2px #3d3d3d;
}

.wrap {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.export-answer {
    margin: 8px 30px 0px 0px;
}

.faqbot-overlay {
    z-index: 2;
}

.flex_attribute {
    justify-content: space-between;
    align-items: center;
}

.custom_input_description {
    font-size: 12px;
    font-family: 'headlinesFont';
    cursor: default;
    letter-spacing: 0.06px;
    color: #3a3a3a;
    padding: 0px 24px 3px 24px;
}

.m_l_30 {
    margin-left: 25px;
}

.center_banner_content {
    justify-content: center;
    align-items: center;
}

.opecity02 {
    opacity: 0.5;
}

.mobile-only,
.mobile-only-flex {
    display: none;
}

.btn-loader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid transparent;
    width: 15px;
    height: 15px;
    position: absolute;
    -webkit-animation: spin 1s linear infinite;
    /* Safari */
    animation: spin 1s linear infinite;
}

/* advance setting icon */
.transform-icon {
    transform: rotate(180deg);
    transition: all 0.35s;
}

.mat-grid-tile-content {
    justify-content: space-evenly !important;
    padding: 0 30px !important;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

// @media (max-width: 768px) {
@media (max-width: 810px) {
    .mat-grid-tile-content {
        display: grid;
    }

    .dashboard_table .mat-mdc-row {
        padding: 7px 10px;
    }

    .dashboard_table .mat-mdc-header-row {
        padding: 7px 10px;
    }

    .mat-mdc-card-content {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    // .stepper__tour {
    //     display: none;
    // }

    .basic-container {
        padding: 90px 15px 30px 15px;
    }

    .mobile-only {
        display: inherit;
    }

    .mobile-only-flex {
        display: -webkit-box !important;
        display: -moz-box !important;
        display: -ms-flexbox !important;
        display: -webkit-flex !important;
        display: flex !important;
        justify-content: space-between;
        width: calc(100% - 54px);
        column-gap: 6px;
    }

    .desktop-only {
        display: none !important;
    }

    .flex-column-mobile {
        flex-direction: column;
    }

    .flex_attribute {
        align-items: unset;
    }

    .p-l-r-24 {
        padding: 0px 10px;
    }

    .card-div-new {
        padding: 10px;
    }

    .card-heading,
    .card-sub-heading {
        padding: 0px 10px;
    }

    .metric-number,
    .legend-text {
        padding: 0px 10px;
    }

    .custom-input,
    .custom-date-time-input,
    .custom-input-type-2 {
        margin: 10px 10px;
        width: calc(100% - 20px);
    }

    .custom_input_description {
        padding: 0px 10px 3px 10px;
    }

    .credits-metrics-container {
        flex-direction: column;
    }

    .credits-metrics-container>div {
        width: 100%;
    }

    .credits-metrics-container>div:last-child {
        text-align: left;
    }

    .error-msg {
        font-size: 10px;
    }
}

/* menu editor */
.dialogue__addcat .mat-mdc-dialog-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    min-height: 300px;
    border-radius: 12px;
    justify-content: center;
    position: relative;
}

.dialogue__addcat .mat-mdc-dialog-container .mdc-dialog__container .mat-mdc-dialog-surface {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dialogue__addsubcat .mat-mdc-dialog-container {
    border-radius: 8px;
    padding: 0px 24px 24px 24px;
    max-height: 90vh;
    display: block;
    min-height: auto;
    position: unset;
    align-items: unset;
    justify-content: unset;
}

::ng-deep.mdc-dialog__surface {
    padding: 20px !important;
}

/*OWL Calendar styles override*/
.owl-dt-container-inner {
    font-family: sans-serif;
}

.faq-bot-button {
    display: none !important;
}

/* New css component */
/* Description info card start*/
.description__card {
    border-radius: 8px;
    padding: 12px;
    font-size: 12px;
}

.description__card--info {
    background: #edf4fc;
    color: #2e5aac;
}

.description__card .info--icon {
    font-size: 16px;
    color: #2e5aac;
    position: relative;
    top: 4px;
}

.description__card--error {
    background: #fef2e9;
    color: #db1f2d;
}

.description__card .error--icon {
    font-size: 16px;
    color: #db1f2d;
    position: relative;
    top: 4px;
}

.description__card--warning {
    background: #fef2e9;
    color: #b95000;
}

.description__card .warning--icon {
    font-size: 16px;
    color: #b95000;
    position: relative;
    top: 4px;
}

/* Description info card end */
#zohohc-asap-web-launcherbox {
    display: none;
}
